@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    html, body, #root {
      @apply h-full;
      @apply bg-black;
    }
}
